import { v4 as uuid } from "uuid";
import moment from "moment";

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { RaceItem } from "../../api";
import { FileButton, Loading, RemoveButton, useTitle } from "../components";
import { ArrayInput, Form, Input, Submit, Switch } from "../components/Form";
import Application from "../components/Application";
import EditText from "../components/EditText";
import { applyAPI, useGet } from "../services/api";
import { useStore } from "../store";
import Error404 from "./404";

const NEW_RACE_ITEM: RaceItem = {
    id: "NEW",
    name: { cs: "", en: "" },
    price: 0,
}

export default function Race() {
    const history = useHistory();
    const { id } = useParams<{ id?: string }>();
    const { l, lang, user, field } = useStore();

    const [application, setApplication] = useState(false);

    const [race, reloadRace, raceError] = useGet("0 GET /api/race/:id", { id: id || "" }, user, typeof id === "string");

    // check payment of this user on this race
    const [status] = useGet("1 GET /api/payment/race/:id", { id: id || "" }, user, user !== null && typeof id === "string");

    // reload table when is status changed
    useEffect(() => {
        if (status && status.state) {
            reloadRace();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useTitle(race ? field(race.name) : l("race"));

    if (typeof id !== "string" || raceError !== null) {
        return <Error404 />;
    } else if (race === null) {
        return <Loading />
    } else if (!user?.admin && moment().diff(moment(race.dateActiveTo).endOf("day")) > 0) {
        return <div className="info">{l("raceNotActive")}</div>;
    } else {
        const isRegistrationTime = moment().diff(moment(race.dateRegistrationTo).endOf("day")) < 0;

        return <>
            <div className="flex race-header">
                {user?.admin && <RemoveButton onRemove={async () => {
                    await applyAPI("1 DELETE /api/race/:id", { id }, user);
                    history.push(`/${lang}/`);
                }} >
                    Smazat závod<br />(ztratíš všechny data závodu)
                </RemoveButton>}
                <div
                    className="image"
                    style={{ backgroundImage: `url(${race.image.url})` }}
                >
                    {user?.admin && <FileButton maxWidth={600 * 2} maxHeight={400 * 2} onFile={async image => {
                        await applyAPI("1 PUT /api/race/:id", { id: race.id, image }, user);
                        reloadRace();
                    }}>
                        Nahrát jiný obrázek
                    </FileButton>}
                </div>
                <div className="race-info">
                    {!user?.admin && <>
                        <h1>{field(race.name)}</h1>
                        <div className="date">
                            {moment(race.dateFrom).format("LL")} - {moment(race.dateTo).format("LL")}
                        </div>
                        <p>{l("freePlaces")}: {race.freeSpaces < 0 ? 0 : race.freeSpaces}</p>
                    </>}
                    {user?.admin && <Form
                        initialValues={{
                            name: race.name,
                            dateFrom: race.dateFrom,
                            dateTo: race.dateTo,
                            dateActiveTo: race.dateActiveTo,
                            dateRegistrationTo: race.dateRegistrationTo,
                            team: race.team,
                            maxMembers: race.maxMembers,
                            commentToApplication: race.commentToApplication,
                        }}
                        onSubmit={async values => {
                            if (user) {
                                await applyAPI("1 PUT /api/race/:id", { id: race.id, ...values }, user);
                                reloadRace();

                                return "Změny jsou uloženy.";
                            } else {
                                return "Změny se nepodařili uložit.";
                            }
                        }}
                    >
                        <Input label="Název" name="name" multiLanguage />
                        <Input label="Začátek závodu" name="dateFrom" type="date" />
                        <Input label="Konec závodu" name="dateTo" type="date" />

                        <Input label="Registrace otevřené do" name="dateRegistrationTo" type="date" />
                        <Input label="Aktivní do" name="dateActiveTo" type="date" />

                        <Switch name="team" labelLeft="Jednotlivec" labelRight="Tým" />

                        <Input label="Maximální počet členů" name="maxMembers" type="number" />

                        <Input label="Komentář při přihlašování" name="commentToApplication" type="textarea" multiLanguage />

                        <p>
                            Přihlášeno: {race.applications.reduce((sum, a) => sum + a.members, 0)} <br />
                            {l("freePlaces")}: {race.freeSpaces}
                        </p>

                        <Submit>Uložit</Submit>
                    </Form>}
                </div>
            </div>

            {/* Classic user */}
            {!user?.admin && <>

                {status && status.state && <p className="info">
                    {status.state === "PAID" && l("successfulPayment")}
                    {status.state !== "PAID" && l("paymentFailed")}
                </p>}

                {!isRegistrationTime && <div className="info">{l("afterRegistration")}</div>}
                {isRegistrationTime && <>
                    {user === null && <div className="info">{l("applicationLoginInfo")}</div>}
                    {user !== null && <>
                        {!application && <button className="big" onClick={() => setApplication(true)}>{l("applicationButton")}</button>}
                        {application && <Application raceId={race.id} onChange={reloadRace} />}
                    </>}
                </>}

                {race.team && <h2>{l("appliedTeams")}</h2>}
                {!race.team && <h2>{l("appliedMember")}</h2>}
                <div className="horizontal-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>{l("name")}</th>
                                {race.team && <th>{l("members")}</th>}
                                <th>{l("items")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {race.applications.map((a) => <tr key={a.id}>
                                <td>{a.name}</td>
                                {race.team && <td>{a.members}</td>}
                                <td>
                                    {a.items.length === 0 && "Žádná"}
                                    {a.items.filter(i => i.quantity > 0).map(i => `${i.quantity}x ${field(i.name)}`).join(", ")}
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                    {race.applications.length === 0 && <div className="info">{l("noApplied")}</div>}
                </div>
            </>}

            {/* Admin */}
            {user?.admin && <RaceAdmin id={race.id} />}
        </>;
    }
}

function RaceAdmin({ id }: { id: string }) {
    const { user } = useStore();
    const [race, reloadRace] = useGet("1 GET /api/race/:id/admin/", { id }, user, typeof id === "string");

    if (race == null) {
        return <Loading />;
    } else {
        return <div className="flex race-admin">
            <div className="flex">
                <h2>Položky</h2>
                <Form initialValues={{ pricePerMember: race.pricePerMember, items: race.items }} onSubmit={async values => {
                    await applyAPI("1 PUT /api/race/:id", { id: race.id, ...values }, user);
                    reloadRace();
                    return "Položky uloženy.";
                }}>
                    <Input label="Cena za člena" unit="Kč" name="pricePerMember" type="number" />
                    <ArrayInput
                        name="items"
                        onAdd={() => ({ ...NEW_RACE_ITEM, id: uuid() })}
                        render={(item, i) => <>
                            <h3>Položka {i + 1}</h3>
                            <Input label="Jméno položky" name="name" multiLanguage type="text" />
                            <Input label="Cena položky" unit="Kč" name="price" type="number" />
                        </>}
                    />
                    <Submit>Uložit</Submit>
                </Form>
            </div>
            <div className="grow">
                <div className="flex export">
                    <h2>Přihlášené týmy</h2>
                    <a className="button" href="#download" download="data.csv" onClick={e => {
                        const a = e.target as HTMLLinkElement;
                        const delimiter = ";";
                        const content = [
                            ["Jméno", "Mail", "Telefon", "Počet členů", "Položky", "Poznámka k přihlášce", "Celkem zaplaceno", "Poznámka admina"].join(delimiter),
                            ...race.applications.map(a => [a.name, a.mail, a.phone, a.members, a.items.filter(i => i.quantity > 0).map(i => `${i.quantity}x ${i.name.cs}`).join(", "), a.userComment, a.paid, a.comment].map(saveDelimiter).join(delimiter))
                        ].join("\n");
                        const file = new Blob([content], { type: "text/csv" });
                        a.href = URL.createObjectURL(file);

                        return true;
                    }}>💾 Export</a>
                </div>

                <div className="horizontal-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Jméno</th>
                                <th>Mail</th>
                                <th>Telefon</th>
                                <th>Počet členů</th>
                                <th>Položky</th>
                                <th>Poznámka k přihlášce</th>
                                <th>Celkem zaplaceno</th>
                                <th>Poznámka admina</th>
                                <th>Smazat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {race.applications.map(a => <tr key={a.id}>
                                <td>{a.name}</td>
                                <td>{a.mail}</td>
                                <td>{a.phone}</td>
                                <td>{a.members}</td>
                                <td>{a.items.filter(i => i.quantity > 0).map(i => `${i.quantity}x ${i.name.cs}`).join(", ")}</td>
                                <td>{a.userComment}</td>
                                <td>
                                    {/*{a.paid.toLocaleString()} Kč */}
                                    <EditText onSave={async paidString => {
                                        const paid = parseFloat(paidString);
                                        await applyAPI("1 PUT /api/race/:id", {
                                            id: race.id, applications: [
                                                ...race.applications.map(app => app !== a ? app : { ...app, paid })
                                            ]
                                        }, user);
                                        reloadRace();
                                    }}>{`${a.paid.toString()} Kč`}</EditText>
                                </td>
                                <td>
                                    <EditText onSave={async comment => {
                                        await applyAPI("1 PUT /api/race/:id", {
                                            id: race.id, applications: [
                                                ...race.applications.map(app => app !== a ? app : { ...app, comment })
                                            ]
                                        }, user);
                                        reloadRace();
                                    }}>{a.comment}</EditText>
                                </td>
                                <td>
                                    <RemoveButton inline onRemove={async () => {
                                        await applyAPI("1 PUT /api/race/:id", {
                                            id: race.id, applications: [
                                                ...race.applications.filter(app => app !== a)
                                            ]
                                        }, user);
                                        reloadRace();
                                    }}>🗑</RemoveButton>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                    {race.applications.length === 0 && <div className="info">Nejsou zde žádné přihlášené týmy</div>}
                </div>
            </div>
        </div >;
    }
}

function saveDelimiter(content: string | number): string {
    const text = String(content);
    return text.split(";").join(",");
}