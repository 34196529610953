import { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";

import { Loading } from ".";
import { ArrayInput, Checkbox, Form, FormValues, Input, Submit } from "./Form";
import { ApplicationOrder, ApplicationItem, RaceItem } from "../../api";
import { applyAPI, useGet } from "../services/api";
import { useStore } from "../store";
import { PayPalButton } from "../services/payPal";


type InitialValues = Omit<ApplicationOrder, "id" | "pay"> & { agreement: boolean };

export default function ApplicationForm({ raceId, onChange }: { raceId: string, onChange: () => void }) {
    const history = useHistory();
    const { user, lang, l, field } = useStore();

    const [ paymentStatus, setPaymentStatus ] = useState("");
    const [ applicationOrder, setApplicationOrder ] = useState<ApplicationOrder | null>(null);

    const [race, reloadRace] = useGet("0 GET /api/race/:id", { id: raceId }, user);

    const [myApplication, reloadMyApplication] = useGet("1 GET /api/race/:id/application/", { id: raceId }, user);

    if (user === null) {
        throw new Error("Not valid state.");
    } else if (race === null || myApplication === null) {
        return <Loading />
    } else {
        const ma = myApplication === "none" ? null : myApplication;
        const initialValues: InitialValues = ma ? {
            raceId: race.id,
            userId: user.id,
            pricePerMember: race.pricePerMember,
            name: ma.name,
            mail: ma.mail,
            phone: ma.phone,
            members: ma.members,
            items: race.items.map(i => ({ ...i, quantity: ma.items.filter(mi => mi.id === i.id).map(i => i.quantity)[0] || 0 })),
            userComment: ma.userComment,
            agreement: false,
        } : {
            raceId: race.id,
            userId: user.id,
            pricePerMember: race.pricePerMember,
            name: "",
            mail: user?.mail || "",
            phone: "",
            members: 1,
            items: race.items.map(i => ({ ...i, quantity: 0 })),
            userComment: "",
            agreement: false,
        };

        return <Form
            initialValues={initialValues}
            onValidate={v => v.agreement === true && 
                v.name.length > 2 && 
                v.mail.length > 6 && 
                v.mail.indexOf("@") > 0 && 
                v.phone.length > 5 && 
                v.members > 0 ? true : false}
            onSubmit={async values => {
                const { agreement, ...application } = values;

                const freePlaces = race.freeSpaces + (ma?.members || 0) - values.members;

                const paySum = getSum(race.pricePerMember, values.members, values.items);
                const pay = paySum - (ma?.paid || 0);

                const newMembers = myApplication === "none" ? 
                    application.members : 
                    application.members - initialValues.members;

                if (newMembers > 0 && freePlaces < 0)
                    return l("noFreePlaces");

                const newItems = application.items.map((item, i) => ({
                    ...item,
                     quantity: item.quantity - initialValues.items[i].quantity
                }));

                let less = false;
                if (newMembers < 0) less = true;

                application.items.forEach((item, i) => {
                    if (item.quantity < initialValues.items[i].quantity) less = true;
                });

                if (less === true) {
                    return l("lessItems");
                }

                if (pay > 0) {
                    setApplicationOrder({
                        ...application,
                        members: newMembers,
                        items: newItems,
                        pay,
                    });
                    return l("paymentLoading");

                } else {
                    await applyAPI("1 PUT /api/race/:id/application/", {
                        id: raceId,
                        ...application,
                    }, user);

                    reloadMyApplication();
                    reloadRace();
                    onChange();

                    return l("applicationSave");
                }
            }}
        >
            {applicationOrder === null && <>
                <h2>{l("applicationTitle")}</h2>

                <Input label={race.team ? `${l("teamName")}*` : `${l("memberName")}*`} name="name" />
                <Input label={`${l("mail")}*`} name="mail" />
                <Input label={`${l("phone")}*`} name="phone" />

                <Splitter getPrice={() => ma?.paid || 0}>
                    <div className="pay-label">{l("paid")}:</div>
                </Splitter>

                {race.team && <Splitter getPrice={(v: InitialValues) => race.pricePerMember * v.members}>
                    <Input label={`${l("members")}*`} name="members" type="number" min={initialValues.members} />
                </Splitter>}

                <ArrayInput name="items" removeButton={false} render={(item: RaceItem, index) => <Splitter getPrice={(v: ApplicationItem) => v.price * v.quantity}>
                    <Input label={field(item.name)} name="quantity" type="number" min={initialValues.items[index].quantity} />
                </Splitter>} />

                <Splitter getPrice={(v: InitialValues) => getSum(race.pricePerMember, v.members, v.items) - (ma?.paid || 0)}>
                    <div className="pay-label">{l("total")}:</div>
                </Splitter>

                <Input label={field(race.commentToApplication)} name="userComment" type="textarea" />

                {/* <p>{l("applicationComment")}</p> */}

                <Checkbox name="agreement" label={<>
                    <a href={`/${lang}/terms-conditions`} target="_blank" rel="noreferrer">{l("agreement")}</a>
                    *
                </>} />

                <FormValues render={(v: InitialValues) => {
                    const sum = getSum(race.pricePerMember, v.members, v.items) - (ma?.paid || 0);
                    const value = sum > 0 ? l("pay") : l("save");

                    return <Submit>{value}</Submit>;
                }} />

            </>}
            {applicationOrder !== null && <>
                <h2>{l("payment")}</h2>
                {!paymentStatus && <>
                    <PayPalButton {...applicationOrder} onChange={status => {
                        setPaymentStatus(status);
                        onChange();
                    }} />
                    <button onClick={() => setApplicationOrder(null)}>{l("paymentCancel")}</button>
                </>}
                {paymentStatus && <>
                    <div className="info">{paymentStatus}</div>
                    <button onClick={() => history.push("/")}>{l("backToHome")}</button>
                </>}
            </>}
        </Form>;
    }
}

function Splitter<V>({ getPrice, children }: { getPrice: (values: V) => number, children: ReactNode }) {
    return <div className="flex splitter">
        <div className="grow">{children}</div>
        <div className="grow">
            <FormValues render={(values: V) => <div className="price">{getPrice(values).toLocaleString()} Kč</div>} />
        </div>
    </div>;
}

function getSum(pricePerMember: number, members: number, items: ApplicationItem[]): number {
    return pricePerMember * members + items.reduce((sum, i) => sum + i.price * i.quantity, 0);
}
