import { Lang } from "../types";

export const dictionary = {
    "title": { cs: "CGSA platby", en: "CGSA payments" },

    // layout
    "logIn": { cs: "Přihlásit se", en: "Log in" },
    "logOut": { cs: "Odhlásit se", en: "Log out" },
    "payments": { cs: "platby", en: "payments" },

    // pages
    "404": { cs: "404 stránka nenalezena 😓", en: "404 page not found 😓" },
    "race": { cs: "Závod", en: "Race" },
    "races": { cs: "Závody", en: "Races" },

    // race
    "freePlaces": { cs: "Volná místa", en: "Free places" },
    "afterRegistration": { cs: "Do závodu se již nelze přihlásit.", en: "It is no longer possible to apply for the race." },
    "applicationLoginInfo": { cs: "Pro podání přihlášky do závodu je nutné se přihlásit", en: "To apply for the race, it is necessary to log in" },
    "applicationButton": { cs: "⇒ Přihlásit do závodu ⇐", en: "⇒ Apply for the race ⇐" },
    "applicationTitle": { cs: "Přihláška do závodu", en: "Race application" },
    "noApplied": { cs: "Nejsou zde žádné přihlášené týmy", en: "There are no applied teams" },
    "noFreePlaces": { cs: "Pro podání této přihlášky není dostatek volných míst", en: "There are not enough free places for submitting this application" },

    "raceNotActive": { cs: "Závod již není aktivní.", en: "The race is no longer active." },

    "appliedTeams": { cs: "Seznam přihlášených týmů", en: "List of applied teams" },
    "appliedMember": { cs: "Seznam přihlášených členů", en: "List of applied members" },
    "items": { cs: "Položky", en: "Items" },

    // Application form
    "name": { cs: "Jméno", en: "Name" },
    "teamName": { cs: "Název týmu", en: "Team name" },
    "memberName": { cs: "Jméno", en: "Name" },
    "mail": { cs: "E-mail", en: "E-mail" },
    "phone": { cs: "Telefon", en: "Phone" },
    "paid": { cs: "Již zaplaceno", en: "Paid" },
    "members": { cs: "Počet členů", en: "Members" },
    "total": { cs: "Celkem", en: "Total" },
    // "applicationComment": { cs: "Toto je přihláška braná vážně. Tedy se už nebudete moci odhlásit a budete mi muset sloužit do konce života.", en: "This is an application taken seriously. So you will no longer be able to unsubscribe and you will have to serve me for the rest of my life." },
    "agreement": { cs: "Souhlasím s všeobecnými obchodními podmínkami", en: "I agree with the general terms and conditions" },
    "pay": { cs: "Zaplatit", en: "Pay" },
    "save": { cs: "Uložit změny", en: "Save changes" },

    "lessItems": { cs: "Zakoupené položky již nelze anulovat.", en: "Purchased items can no longer be canceled." },
    "backToHome": { cs: "Zpět do přehledu závodů", en: "Back to the list of races" },

    "payment": { cs: "Platba", en: "Payment" },
    "paymentCancel": { cs: "Zrušit platbu", en: "Cancel payment" },
    "successfulPayment": { cs: "Platba byla úspěšně přijata.", en: "Payment received successfully." },
    "paymentFailed": { cs: "Platba nebyla přijata.", en: "Payment not received." },
    "paymentState": { cs: "Stav vaší platby je", en: "Your payment status is" },
    "applicationSave": { cs: "Vaše přihláška byla uložena.", en: "Your application has been saved." },
    "paymentLoading": { cs: "Platba se připravuje.", en: "Payment in preparation." },

    // contact
    "web": { cs: "Web", en: "Web" },
    "contact": { cs: "Kontakt", en: "Contact" },
    "IN": { cs: "IČ", en: "IN" },
    "TIN": { cs: "DIČ", en: "TIN" },

    // footer
    "principles": { cs: "Zásady", en: "Principles" },
    "termsAndConditions": { cs: "Všeobecné obchodní podmínky", en: "General Terms and Conditions" },
    "rights": { cs: "© 2021 Všechna práva vyhrazena CGSA", en: "© 2021 All rights reserved CGSA" },
};

export default function lang(language: Lang, key: keyof typeof dictionary): string {
    return dictionary[key][language];
}

export function showField(language: Lang, field: { cs: string, en: string }): string {
    if (field[language]) {
        return field[language];
    }
    return field.en || field.cs;
}
