import React, { ReactNode, useEffect, useState } from "react";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import type { CreateOrderRequestBody } from "@paypal/paypal-js/types/apis/orders";

import { Loading } from "../components";
import { useStore } from "../store";
import { ApplicationOrder } from "../types";

import { applyAPI } from "./api";


export function PayPallProvider({ children }: { children: ReactNode }) {
    return <PayPalScriptProvider options={{
        "client-id": "ATkNMmSBXoX19dNuU83EyZ-ri1OwypsRNfW7sIzZKAh-2mO8D-tgTIcbqY56kKpGnLSYsvu16zGgsD4y",
        "disable-funding": "p24",
        currency: "CZK",
        components: "buttons",
        intent: "capture"
    }}>
        {children}
    </PayPalScriptProvider>
}

export function PayPalButton({ onChange, ...application }: ApplicationOrder & { onChange: (status: string) => void }) {
    const { user } = useStore();
    const [isApprove, setIsApprove] = useState(false);
    const [paymentId, setPaymentId] = useState<null | string>(null);

    if (!isApprove || paymentId === null) {
        return <div style={{ textAlign: "center" }}>
            <PayPalButtons
                createOrder={async (data, actions) => {
                    const paymentId = await actions.order.create(createPayment(application));
                    const { id } = await applyAPI("1 POST /api/payment/", { ...application, paymentId }, user);
                    setPaymentId(id);
                    return paymentId;
                }}
                onApprove={async (data, actions) => {
                    setIsApprove(true);
                    // ! capture is allowed only once! Do it on server!
                    // return actions.order!.capture().then((details) => {
                    //     console.log(details);
                    // });
                    return;
                }}
            />
        </div>;
    } else {
        return <PaymentResult id={paymentId} onChange={onChange} />;
    }
}

export function createPayment({ raceId, members, pricePerMember, items, pay }: ApplicationOrder): CreateOrderRequestBody {

    let payPalItems = [];
    if (members > 0) {
        payPalItems.push({
            "name": "Člen / Member",
            "unit_amount": {
                "currency_code": "CZK",
                "value": pricePerMember.toString(),
            },
            "tax": {
                "currency_code": "CZK",
                "value": "0.00",
            },
            "quantity": members.toString(),
        });
    }
    items.filter(i => i.quantity > 0).forEach(({ name, price, quantity }) => payPalItems.push({
        "name": getItemName(name),
        "unit_amount": {
            "currency_code": "CZK",
            "value": price.toString(),
        },
        "tax": {
            "currency_code": "CZK",
            "value": "0.00"
        },
        "quantity": quantity.toString(),
    }));

    const itemTotal = members * pricePerMember + items.reduce((sum, item) => item.price * item.quantity + sum, 0);

    if (itemTotal < pay) {
        payPalItems.push({
            "name": "Dorovnání / Balance",
            "unit_amount": {
                "currency_code": "CZK",
                "value": (pay - itemTotal).toString(),
            },
            "tax": {
                "currency_code": "CZK",
                "value": "0.00",
            },
            "quantity": "1",
        });
    } else if (itemTotal > pay) {
        // admin should remove paid many for user
        payPalItems = [
            {
                "name": "Platba / Payment",
                "unit_amount": {
                    "currency_code": "CZK",
                    "value": pay.toString(),
                },
                "tax": {
                    "currency_code": "CZK",
                    "value": "0.00",
                },
                "quantity": "1",
            }
        ]
    }

    return {
        "intent": "CAPTURE",
        "purchase_units": [
            {
                "amount": {
                    "currency_code": "CZK",
                    "value": pay.toString(),
                    "breakdown": {
                        "item_total": {
                            "currency_code": "CZK",
                            "value": pay.toString(),
                        }
                    },
                },
                "items": payPalItems,
            }
        ]
    };
}

function getItemName({ cs, en }: { cs: string, en: string }): string {
    let name = "";

    if (cs) {
        name += cs;
    }
    if (en) {
        if (name)
            name += " / ";
        name += en;
    }

    return name;
}


function PaymentResult({ id, onChange }: { id: string, onChange: (status: string) => void }) {
    const { user, l } = useStore();

    const [status, setStatus] = useState<null | string>(null);

    useEffect(() => {
        (async () => {
            const { state } = await applyAPI("1 GET /api/payment/:id", { id }, user);

            let status = l("paymentFailed");
            if (state === "PAID") {
                status = l("successfulPayment");
            }

            setStatus(status);
            onChange(status);
        })()
    }, [id, user, l, onChange]);

    if (status === null) {
        return <Loading />;
    } else {
        return <div className="info">
            {status}
        </div>;
    }
}