import { Link } from "react-router-dom";
import { useTitle } from "../components";
import { useStore } from "../store";

export default function TermsAndConditions() {
    const { lang } = useStore();

    useTitle("Všeobecné obchodní podmínky");

    return <div>
        <h1>Všeobecné obchodní podmínky</h1>

        <h2>I. Základní ustanovení</h2>
        <ol>

            <li>Tyto všeobecné obchodní podmínky (dále jen „obchodní podmínky“) jsou vydané dle § 1751 a násl. zákona č. 89/2012 Sb., občanský zákoník (dále jen „občanský zákoník“)
                <br />
                <b>(Czech Gravity Sports Association z.s.)</b><br />
                IČ: 26566311<br />
                DIČ: CZ 26566311<br />
                se sídlem:<br />
                zapsané u  Městského soudu v Praze, oddíl L vložka 19942<br />
                kontaktní údaje:<br />
                e-mail:	info@cgsa.cz<br />
                {/* telefon: 777907855<br /> */}
                web: platby.cgsa.cz<br />
                (dále jen „prodávající“)<br /><br />
            </li>
            <li>Tyto obchodní podmínky upravují vzájemná práva a povinnosti prodávajícího a fyzické osoby, která uzavírá kupní smlouvu mimo svou podnikatelskou činnost jako spotřebitel, nebo v rámci své podnikatelské činnosti (dále jen: „kupující“) prostřednictvím webového rozhraní umístěného na webové stránce dostupné platby.cgsa.cz. (dále jen „internetový obchod“). </li>
            <li>Ustanovení obchodních podmínek jsou nedílnou součástí kupní smlouvy. Odchylná ujednání v kupní smlouvě mají přednost před ustanoveními těchto obchodních podmínek.</li>
            <li>Tyto obchodní podmínky a kupní smlouva se uzavírají v českém jazyce. </li>
        </ol>

        <h2>II. Informace o zboží a cenách</h2>
        <ol>
            <li>Informace o zboží, včetně uvedení cen jednotlivého zboží a jeho hlavních vlastností, jsou uvedeny u jednotlivého zboží v katalogu internetového obchodu. Ceny zboží jsou uvedeny včetně daně z přidané hodnoty, všech souvisejících poplatků. Ceny zboží zůstávají v platnosti po dobu, po kterou jsou zobrazovány v internetovém obchodě. Toto ustanovení nevylučuje sjednání kupní smlouvy za individuálně sjednaných podmínek.</li>
            <li>Veškerá prezentace zboží umístěná v katalogu internetového obchodu je informativního charakteru a prodávající není povinen uzavřít kupní smlouvu ohledně tohoto zboží. </li>
            <li>V internetovém obchodě jsou zveřejněny informace o nákladech spojených s balením a dodáním zboží. </li>
            <li>Případné slevy z kupní ceny zboží nelze navzájem kombinovat, nedohodne-li se prodávající s kupujícím jinak. </li>
        </ol>

        <h2>III. Objednávka a uzavření kupní smlouvy</h2>
        <ol>
            <li>Náklady vzniklé kupujícímu při použití komunikačních prostředků na dálku v souvislosti s uzavřením kupní smlouvy (náklady na internetové připojení, náklady na telefonní hovory), hradí kupující sám. Tyto náklady se neliší od základní sazby.</li>
            <li>Kupující provádí objednávku zboží tímto způsobem:
                <ul>
                    <li>prostřednictvím svého zákaznického účtu, provedl-li  v internetovém obchodě, </li>
                </ul>
            </li>
            <li>Při zadávání objednávky si kupující vybere zboží, počet kusů zboží, způsob platby.</li>
            <li>Před odesláním objednávky je kupujícímu umožněno kontrolovat a měnit údaje, které do objednávky vložil. Objednávku kupující odešle prodávajícímu kliknutím na tlačítko "Zaplatit" nebo "Pay". Údaje uvedené v objednávce jsou prodávajícím považovány za správné. Podmínkou platnosti objednávky je vyplnění všech povinných údajů v objednávkovém formuláři a potvrzení kupujícího o tom, že se s těmito obchodními podmínkami seznámil.</li>
            <li>Neprodleně po obdržení objednávky zašle prodávající kupujícímu potvrzení o obdržení objednávky na e-mailovou adresu, kterou kupující při objednání zadal. Toto potvrzení je automatické a nepovažuje se za uzavření smlouvy. Přílohou potvrzení jsou aktuální obchodní podmínky prodávajícího. Kupní smlouva je uzavřena až po přijetí objednávky prodávajícím Oznámení o přijetí objednávky je doručeno na e-mailovou adresu kupujícího.  </li>
            <li>V případě, že některý z požadavků uvedených v objednávce nemůže prodávající splnit, zašle kupujícímu na jeho e-mailovou adresu pozměněnou nabídku. Pozměněná nabídka se považuje za nový návrh kupní smlouvy a kupní smlouva je v takovém případě uzavřena potvrzením kupujícího o přijetí této nabídky prodávajícímu na jeho e-mailovou adresu uvedenou v těchto obchodních podmínkách. </li>
            <li>Všechny objednávky přijaté prodávajícím jsou závazné. Kupující může zrušit objednávku, dokud není kupujícímu doručeno oznámení o přijetí objednávky prodávajícím. Kupující může zrušit objednávku telefonicky na telefonní číslo nebo e-mail prodávajícího uvedený v těchto obchodních podmínkách. </li>
            <li>V případě, že došlo ke zjevné technické chybě na straně prodávajícího při uvedení ceny zboží v internetovém obchodě nebo v průběhu objednávání, není prodávající povinen dodat kupujícímu zboží za tuto zcela zjevně chybnou cenu ani v případě, že kupujícímu bylo zasláno automatické potvrzení o obdržení objednávky podle těchto obchodních podmínek. Prodávající informuje kupujícího o chybě bez zbytečného odkladu a zašle kupujícímu na jeho e-mailovou adresu pozměněnou nabídku. Pozměněná nabídka se považuje za nový návrh kupní smlouvy a kupní smlouva je v takovém případě uzavřena potvrzením o přijetí kupujícím na e-mailovou adresu prodávajícího.</li>
        </ol>

        <h2>IV. Zákaznický účet</h2>
        <ol>
            <li>Na základě registrace kupujícího provedené v internetovém obchodě může kupující přistupovat do svého zákaznického účtu. Ze svého zákaznického účtu může kupující provádět objednávání zboží. Kupující může objednávat zboží také bez registrace. </li>
            <li>Při registraci do zákaznického účtu a při objednávání zboží je kupující povinen uvádět správně a pravdivě všechny údaje. Údaje uvedené v uživatelském účtu je kupující při jakékoliv jejich změně povinen aktualizovat. Údaje uvedené kupujícím v zákaznickém účtu a při objednávání zboží jsou prodávajícím považovány za správné.</li>
            <li>Přístup k zákaznickému účtu je zabezpečen uživatelským jménem a heslem. Kupující je povinen zachovávat mlčenlivost ohledně informací nezbytných k přístupu do jeho zákaznického účtu. Prodávající nenese odpovědnost za případné zneužití zákaznického účtu třetími osobami.</li>
            <li>Kupující není oprávněn umožnit využívání zákaznického účtu třetím osobám.</li>
            <li>Prodávající může zrušit uživatelský účet a to zejména v případě, když kupující svůj uživatelský účet déle nevyužívá, či v případě, kdy kupující poruší své povinnosti z kupní smlouvy nebo těchto obchodních podmínek.</li>
            <li>Kupující bere na vědomí, že uživatelský účet nemusí být dostupný nepřetržitě a to zejména s ohledem na nutnou údržbu hardwarového a softwarového vybavení prodávajícího, popř. nutnou údržbu hardwarového a softwarového vybavení třetích osob.</li>
        </ol>

        <h2>V. Platební podmínky a dodání zboží</h2>
        <ol>
            <li>Cenu zboží a případné náklady spojené s dodáním zboží dle kupní smlouvy může kupující uhradit následujícími způsoby:
                <ul>
                    <li>bezhotovostně platební kartou,</li>
                </ul>
            </li>
            <li>Společně s kupní cenou je kupující povinen uhradit prodávajícímu náklady spojené s balením a dodáním zboží ve smluvené výši. Není-li dále uvedeno výslovně jinak, rozumí se dále kupní cenou i náklady spojené s dodáním zboží.</li>
            <li>V případě bezhotovostní platby je závazek kupujícího uhradit kupní cenu splněn okamžikem připsání příslušné částky na bankovní účet prodávajícího.</li>
            <li>Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku. Zároveň je povinen zaevidovat přijatou tržbu u správce daně online, v případě technického výpadku pak nejpozději do 48 hodin</li>
            <li>Je-li prodávající podle kupní smlouvy povinen dodat zboží na místo určené kupujícím v objednávce, je kupující povinen převzít zboží při dodání. V případě, že je z důvodů na straně kupujícího nutno zboží doručovat opakovaně nebo jiným způsobem, než bylo uvedeno v objednávce, je kupující povinen uhradit náklady spojené s opakovaným doručováním zboží, resp. náklady spojené s jiným způsobem doručení.</li>
            <li>Kupující nabývá vlastnické právo ke zboží zaplacením celé kupní ceny za zboží včetně nákladů na dodání. </li>
            <li>Odpovědnost za nahodilou zkázu, poškození či ztrátu zboží přechází na kupujícího okamžikem převzetí zboží nebo okamžikem, kdy měl kupující povinnost zboží převzít, ale v rozporu s kupní smlouvou tak neučinil.</li>
        </ol>

        <h2>VI. Odstoupení od smlouvy</h2>
        <ol>
            <li> Kupující, který uzavřel kupní smlouvu mimo svou podnikatelskou činnost jako spotřebitel, má právo od kupní smlouvy odstoupit.</li>
            <li> Lhůta pro odstoupení od smlouvy činí 14 dnů
                <ul>
                    <li>ode dne převzetí zboží,</li>
                </ul>
            </li>
            <li> Kupující nemůže mimo jiné odstoupit od kupní smlouvy
                <ul>
                    <li>poskytování služeb, jestliže byly splněny s jeho předchozím výslovným souhlasem před uplynutím lhůty pro odstoupení od smlouvy a prodávající před uzavřením smlouvy sdělil kupujícímu, že v takovém případě nemá právo na odstoupení od smlouvy,</li>
                    <li>o dodávce zboží, které bylo upraveno podle přání kupujícího nebo pro jeho osobu,</li>
                    <li>v dalších případech uvedených v § 1837 občanského zákoníku.</li>
                </ul>
            </li>
            <li> Pro dodržení lhůty pro odstoupení od smlouvy musí kupující odeslat prohlášení o odstoupení ve lhůtě pro odstoupení od smlouvy.</li>
            <li> Pro odstoupení od kupní smlouvy může kupující využít vzorový formulář k odstoupení od smlouvy poskytovaný prodávajícím. Odstoupení od kupní smlouvy zašle kupující na e-mailovou nebo doručovací adresu prodávajícího uvedenou v těchto obchodních podmínkách. Prodávající potvrdí kupujícímu bezodkladně přijetí formuláře.</li>
            <li> Kupující, který odstoupil od smlouvy, je povinen vrátit prodávajícímu zboží do 14 dnů od odstoupení od smlouvy prodávajícímu. Kupující nese náklady spojené s navrácením zboží prodávajícímu, a to i v tom případě, kdy zboží nemůže být vráceno pro svou povahu obvyklou poštovní cestou.</li>
            <li> Odstoupí-li kupující od smlouvy, vrátí mu prodávající bezodkladně, nejpozději však do 14 dnů od odstoupení od smlouvy všechny peněžní prostředky včetně nákladů na dodání, které od něho přijal, a to stejným způsobem. Prodávající vrátí kupujícímu přijaté peněžení prostředky jiným způsobem jen tehdy, pokud s tím kupující souhlasí a pokud mu tím nevzniknou další náklady.</li>
            <li> Odstoupí-li kupující od kupní smlouvy, není prodávající povinen vrátit přijaté peněžní prostředky kupujícímu dříve, než mu kupující zboží předá nebo prokáže, že zboží prodávajícímu odeslal.</li>
            <li> Prodávající je oprávněn odstoupit od kupní smlouvy z důvodu vyprodání zásob, nedostupnosti zboží, nebo když výrobce, dovozce nebo dodavatel zboží přerušil výrobu nebo dovoz zboží. Prodávající bezodkladně informuje kupujícího prostřednictvím e-mailové adresy uvedené v objednávce a vrátí ve lhůtě 14 dnů od oznámení o odstoupení od kupní smlouvy všechny peněžní prostředky včetně nákladů na dodání, které od něho na základě smlouvy přijal, a to stejným způsobem, popřípadě způsobem určeným kupujícím. </li>
        </ol>

        <h2>VII. Doručování</h2>
        <ol>
            <li>1. Smluvní strany si mohou veškerou písemnou korespondenci vzájemně doručovat prostřednictvím elektronické pošty. </li>
            <li>2. Kupující doručuje prodávajícímu korespondenci na e-mailovou adresu uvedenou v těchto obchodních podmínkách. Prodávající doručuje kupujícímu korespondenci na e-mailovou adresu uvedenou v jeho zákaznickém účtu nebo v objednávce. </li>
        </ol>

        <h2>IX. Osobní údaje</h2>
        <ol>
            <li>Všechny informace, které kupující při spolupráci s prodávajícím uvede, jsou důvěrné a bude s nimi tak zacházeno. Pokud kupující nedá prodávajícímu písemné svolení, údaje o kupujícím nebude prodávající jiným způsobem než za účelem plnění ze smlouvy používat, vyjma e-mailové adresy, na kterou mohou být zasílána obchodní sdělení, neboť tento postup umožňuje zákon, pokud není vysloveně odmítnut. Tato sdělení se mohou týkat pouze obdobného nebo souvisejícího zboží a lze je kdykoliv jednoduchým způsobem (zasláním dopisu, e-mailu nebo proklikem na odkaz v obchodním sdělení) odhlásit. E-mailová adresa bude za tímto účelem uchovávána po dobu 3 let od uzavření poslední smlouvy mezi smluvními stranami. </li>
            <li>Podrobnější informace o ochraně osobních údajů naleznete v Zásadách ochrany osobních údajů <Link to={`/${lang}/principles`}>ZDE</Link></li>
        </ol>

        <h2>IX. Mimosoudní řešení sporů</h2>
        <ol>
            <li>K mimosoudnímu řešení spotřebitelských sporů z kupní smlouvy je příslušná Česká obchodní inspekce se sídlem Štěpánská 567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa: https://adr.coi.cz/cs. Platformu pro řešení sporů on-line nacházející se na internetové adrese http://ec.europa.eu/consumers/odr je možné využít při řešení sporů mezi prodávajícím a kupujícím z kupní smlouvy.</li>
            <li>Evropské spotřebitelské centrum Česká republika se sídlem Štěpánská 567/15, 120 00 Praha 2, internetová adresa: http://www.evropskyspotrebitel.cz je kontaktním místem podle Nařízení Evropského parlamentu a Rady (EU) č. 524/2013 ze dne 21. května 2013 o řešení spotřebitelských sporů on-line a o změně nařízení (ES) č. 2006/2004 a směrnice 2009/22/ES (nařízení o řešení spotřebitelských sporů on-line).</li>
            <li>Prodávající je oprávněn k prodeji zboží na základě živnostenského oprávnění. Živnostenskou kontrolu provádí v rámci své působnosti příslušný živnostenský úřad. Česká obchodní inspekce vykonává ve vymezeném rozsahu mimo jiné dozor nad dodržováním zákona č. 634/1992 Sb., o ochraně spotřebitele.</li>
        </ol>

        <h2>X. Závěrečná ustanovení</h2>
        <ol>
            <li>Veškerá ujednání mezi prodávajícím a kupujícím se řídí právním řádem České republiky. Pokud vztah založený kupní smlouvou obsahuje mezinárodní prvek, pak strany sjednávají, že vztah se řídí právem České republiky. Tímto nejsou dotčena práva spotřebitele vyplývající z obecně závazných právních předpisů.</li>
            <li>Prodávající není ve vztahu ke kupujícímu vázán žádnými kodexy chování ve smyslu ustanovení § 1826 odst. 1 písm. e) občanského zákoníku.</li>
            <li>Všechna práva k webovým stránkám prodávajícího, zejména autorská práva k obsahu, včetně rozvržení stránky, fotek, filmů, grafik, ochranných známek, loga a dalšího obsahu a prvků, náleží prodávajícímu. Je zakázáno kopírovat, upravovat nebo jinak používat webové stránky nebo jejich část bez souhlasu prodávajícího.</li>
            <li>Prodávající nenese odpovědnost za chyby vzniklé v důsledku zásahů třetích osob do internetového obchodu nebo v důsledku jeho užití v rozporu s jeho určením. Kupující nesmí při využívání internetového obchodu používat postupy, které by mohly mít negativní vliv na jeho provoz a nesmí vykonávat žádnou činnost, která by mohla jemu nebo třetím osobám umožnit neoprávněně zasahovat či neoprávněně užít programové vybavení nebo další součásti tvořící internetový obchod a užívat internetový obchod nebo jeho části či softwarové vybavení takovým způsobem, který by byl v rozporu s jeho určením či účelem. </li>
            <li>Kupující tímto přebírá na sebe nebezpečí změny okolností ve smyslu § 1765 odst. 2 občanského zákoníku.</li>
            <li>Kupní smlouva včetně obchodních podmínek je archivována prodávajícím v elektronické podobě a není přístupná.</li>
            <li>Znění obchodních podmínek může prodávající měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklé po dobu účinnosti předchozího znění obchodních podmínek.</li>
            <li>Přílohou obchodních podmínek je vzorový formulář pro odstoupení od smlouvy.</li>
        </ol>

        <p>Tyto obchodní podmínky nabývají účinnosti dnem 20. 1. 2021.</p>
    </div>;
}
