import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";

import language, { dictionary, showField } from "../services/lang";
import { Lang, User } from "../types";
import { applyAPI } from "../services/api";

const ORIGIN = process.env.NODE_ENV === "development" ? "http://localhost:5800" : window.location.origin;

type ContextType = {
    lang: Lang,
    setLang: (lang: Lang) => void,
    user: null | User,
    setUser: (user: null | User) => void,
}

const defaultContext: ContextType = {
    lang: "en",
    setLang: () => { },
    user: null,
    setUser: () => { },
};

const Context = React.createContext(defaultContext);

export function Provider({ children }: { children: JSX.Element }) {
    const { isAuthenticated, user: auth0User, getAccessTokenSilently } = useAuth0();

    const [lang, setLang] = useState<Lang>(defaultContext.lang);
    const [user, setUser] = useState<null | User>(defaultContext.user);

    const onChangeLang = useCallback((lang: Lang) => {
        const html = document.getElementsByTagName("html")[0];
        if (html)
            html.lang = lang;

        moment.locale(lang);
        setLang(lang);
    }, []);

    // Log in/out
    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                const accessToken = await getAccessTokenSilently();
                const user: User = {
                    id: auth0User!.sub || "",
                    name: auth0User!.name || "",
                    mail: auth0User!.mail || null,
                    picture: auth0User!.picture || "",
                    accessToken: accessToken,
                    admin: false,
                };

                let userInfo = {};
                try {
                    userInfo = await applyAPI("1 GET /api/user/", {}, user);
                } catch (e) {
                    await applyAPI("1 PUT /api/user/", { id: user.id, name: user.name, admin: false }, user);
                    userInfo = await applyAPI("1 GET /api/user/", {}, user);
                }

                setUser({
                    ...user,
                    ...userInfo,
                });
            } else {
                setUser(null);
            }
        })();
    }, [isAuthenticated, auth0User, setUser, getAccessTokenSilently]);

    return <Context.Provider value={{
        lang,
        setLang: onChangeLang,
        user,
        setUser,
    }}>
        {children}
    </Context.Provider>;
}

type UseStore = ContextType & {
    l: (key: keyof typeof dictionary) => string,
    field: (filed: { cs: string, en: string }) => string,
    origin: string,
};

export function useStore(): UseStore {
    const context = useContext(Context);

    const l = useCallback((key: keyof typeof dictionary) => {
        return language(context.lang, key);
    }, [context.lang]);

    const field = useCallback((filed: { cs: string, en: string }) => {
        return showField(context.lang, filed);
    }, [context.lang]);

    return {
        ...context,
        l,
        field,
        origin: ORIGIN,
    };
}