import { useTitle } from "../components";
import { useStore } from "../store";

export default function Error404() {
    const { l } = useStore();

    useTitle("404");

    return <h1 className="error">{l("404")}</h1>;
}
