import { useTitle } from "../components";
import { useStore } from "../store";

export default function Contact() {
    const { l } = useStore();

    useTitle(l("contact"));

    return <div>
        <h1>{l("contact")}</h1>

        <h2>Czech Gravity Sports Association z.s.</h2>
        <p>
            <b>{l("IN")}:</b> 26566311<br />
            <b>{l("TIN")}:</b> CZ 26566311<br /><br />

            <b>{l("mail")}:</b> <a href="mailto:info@cgsa.cz">info@cgsa.cz</a><br />
            {/* <b>{l("phone")}:</b> <a href="tel:+420777907855">+420 777 907 855</a><br /> */}
            <b>{l("web")}:</b> <a href="https://platby.cgsa.cz">platby.cgsa.cz</a><br />
        </p>
    </div>;
}
