import { useEffect, useState } from "react";
import { API, User } from "../types";

const SERVER_URL = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";

export async function applyAPI<K extends keyof API>(key: K, params: API[K]["params"], user: null | User = null): Promise<API[K]["response"]> {
    const [auth, method, url] = key.split(" ");

    const resolvedUrl = url.indexOf(":id") > -1 ? url.replace(":id", (params as any).id) : url;

    const headers: { [key: string]: string } = { "Content-Type": "application/json" };
    if (user?.accessToken)
        headers["authorization"] = `Bearer ${user.accessToken}`;

    if (auth === "1" && !user?.accessToken)
        throw new Error("User is not set.");

    const response = await fetch(
        `${SERVER_URL}${resolvedUrl}${method === "GET" || method === "DELETE" ? `?${Object.keys(params).map(key => `${key}=${decodeURIComponent((params as any)[key])}`)}` : ""}`,
        {
            method,
            headers,
            body: method === "POST" || method === "PUT" ? JSON.stringify(params) : undefined
        }
    );
    const json = await response.json();
    if (response.status === 200) {
        return json;
    } else {
        throw new Error(json.message || "Server error.");
    }
}


// hooks
export function useGet<K extends keyof API>(key: K, params: API[K]["params"], user: null | User = null, use: boolean = true): [data: null | API[K]["response"], reload: () => void, error: null | Error] {
    const [data, setData] = useState<null | API[K]["response"]>(null);
    const [error, setError] = useState<null | Error>(null);
    const [reload, setReload] = useState(true);

    useEffect(() => {
        if (use) {
            (async () => {
                try {
                    setError(null);
                    const data = await applyAPI(key, params, user);
                    setData(data);
                } catch (e) {
                    setError(e as Error);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, JSON.stringify(params), reload, user, use]);

    // TODO: error boundary catch
    if (data instanceof Error) throw data;

    return [data, () => setReload(r => !r), error];
}
