import { useEffect } from "react";
import { Switch, Route, Redirect, useParams, useRouteMatch, useLocation } from "react-router-dom";

import Races from "./pages/Races";
import Race from "./pages/Race";
import Error404 from "./pages/404";
import Contact from "./pages/Contact";
import Principles from "./pages/Principles";
import TermsAndConditions from "./pages/TermsAndConditions";

import { useStore } from "./store";

export default function Routes() {
    return <Route path={["/:lang(cs|en)", "/"]} component={LangRoute} />;
}

function LangRoute() {
    const { setLang } = useStore();
    const { pathname, search } = useLocation();
    const { lang } = useParams<{ lang?: "cs" | "en" }>();
    const { path } = useRouteMatch();

    useEffect(() => {
        if (lang) setLang(lang);
    }, [lang, setLang]);

    if (pathname === "/") {
        if (navigator.language === "cs") {
            return <Redirect to={{ pathname: "/cs", search }} />;
        } else {
            return <Redirect to={{ pathname: "/en", search }} />;
        }
    } else {
        return <Switch>
            <Route path={path} exact component={Races} />
            <Route path={`${path}/race/:id`} exact component={Race} />
            <Route path={`${path}/contact`} exact component={Contact} />
            <Route path={`${path}/principles`} exact component={Principles} />
            <Route path={`${path}/terms-conditions`} exact component={TermsAndConditions} />
            <Route path="*" component={Error404} />
        </Switch>;
    }
}