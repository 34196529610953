
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import ErrorBoundary from './ErrorBoundary';
import { useStore } from "../store";

import { Lang } from "../types";

import packageInfo from "../../package.json";

export default function Layout({ children }: { children: JSX.Element }) {
    const { l, lang, user } = useStore();
    const { loginWithRedirect, logout } = useAuth0();

    return <>
        <header>
            <Link to={`/${lang}`} className="logo">
                <img src="/logo.png" alt="CGSA logo" />
                CGSA {l("payments")}
            </Link>
            <div className="flex top-option">
                <LangSwitch />
                {!user && <button onClick={() => loginWithRedirect()}>{l("logIn")}</button>}
                {user && <>
                    <div className="flex user">
                        {/* TODO: not permission */}
                        <img src={user.picture} alt={user.name} />
                        <div className="name">{user.name}{user.admin ? " (admin)" : ""}</div>
                        <button className="inline" onClick={() => logout({ returnTo: window.location.origin })}>
                            {l("logOut")}
                        </button>
                    </div>
                </>}

            </div>
        </header>
        <div className="flex main-content">
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </div>
        <footer>
            <div>
                Czech Gravity Sports Association z.s. <br />
                <Link to={`/${lang}/contact`}>{l("contact")}</Link> <br />
                <span className="version">v{packageInfo.version}</span>
            </div>
            <div className="rights">
                {l("rights")} <br />
                <Link to={`/${lang}/terms-conditions`}>{l("termsAndConditions")}</Link> <br />
                <Link to={`/${lang}/principles`}>{l("principles")}</Link>
            </div>
        </footer>
    </>;
}

function LangSwitch() {
    const history = useHistory();
    const { pathname } = useLocation();
    const { lang } = useStore();

    function changeLang(lang: Lang) {
        const newPath = pathname.replace("/cs", `/${lang}`).replace("/en", `/${lang}`);
        history.push(newPath);
    }

    return <div className="flex lang-switch">
        <button className={lang === "cs" ? "inline disable" : "inline"} onClick={() => changeLang("cs")}>CS</button>
        {" / "}
        <button className={lang === "en" ? "inline disable" : "inline"} onClick={() => changeLang("en")}>EN</button>
    </div>
}