import React from "react";

export default class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.    
        return { hasError: true };
    }
    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service  
        // TODO: only for develop
        if (process.env.NODE_ENV === "development")
            console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI     
            return <div className="error">
                <h1>Something went wrong.</h1>
                <p>Click <a href="/">here</a> for refresh website.</p>
            </div>;
        }
        return this.props.children;
    }
}