import moment from "moment";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { RemoveButton } from ".";
import { useStore } from "../store";

type FormContextType<V> = {
    values: V,
    isValid: boolean,
    submitMessage: null | string,
    setValue: (name: string, value: any) => void,
    onSubmit: () => void,
};
const FormContext = createContext<FormContextType<any>>({
    values: {},
    isValid: false,
    submitMessage: null,
    setValue: () => { },
    onSubmit: () => { },
});

Form.defaultProps = {
    onValidate: () => true,
};

type FormProps<V> = {
    initialValues: V,
    children: ReactNode,
    onValidate: (values: V) => boolean,
    onSubmit: (values: V) => Promise<null | string>,
}

export function Form<V extends { [name: string]: any }>({ children, initialValues, onValidate, onSubmit }: FormProps<V>) {
    const [values, setValues] = useState<V>(initialValues);
    const [submitMessage, setSubmitMessage] = useState<null | string>(null);

    useEffect(() => {
        setValues(initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(initialValues)]);

    return <FormContext.Provider value={{
        values,
        submitMessage,
        isValid: onValidate(values),
        setValue: (name, value) => {
            setValues(values => ({ ...values, [name]: value }));
            setSubmitMessage(null);
        },
        onSubmit: async () => {
            const message = await onSubmit(values);
            setSubmitMessage(message);
        },
    }}>
        {children}
    </FormContext.Provider>;
}

export function FormValues<V>({ render }: { render: (values: V) => JSX.Element }) {
    const { values } = useContext(FormContext);
    return render(values);
}

type ArrayInputProps<T> = {
    name: string,
    removeButton: boolean,
    onAdd: null | (() => T),
    render: (item: T, index: number) => ReactNode
};

ArrayInput.defaultProps = {
    onAdd: null,
    removeButton: true,
};

export function ArrayInput<T>({ name, onAdd, removeButton, render }: ArrayInputProps<T>) {
    const { values, setValue } = useContext(FormContext);

    const items: T[] = values[name];

    return <>
        {items.map((item, i) => <FormContext.Provider key={i} value={{
            values: item,
            submitMessage: null,
            isValid: false,
            setValue: (subName, value) => {
                setValue(name, [...items.map(editItem => editItem !== item ? editItem : { ...editItem, [subName]: value })]);
            },
            onSubmit: () => { },
        }}>
            <div className="input-array-item">
                {removeButton && <RemoveButton onRemove={() => setValue(name, [...items.filter(i => i !== item)])} >🗑</RemoveButton>}
                {render(item, i)}
            </div>
        </FormContext.Provider>)}
        {onAdd !== null && <button onClick={() => setValue(name, [...items, onAdd()])}>+ Přidat</button>}
    </>;
}

type InputProps = {
    label: string,
    placeholder: string,
    name: string,
    type: "text" | "textarea" | "number" | "date",
    unit: string,
    multiLanguage: boolean,
    min: number,
}

Input.defaultProps = {
    label: "",
    placeholder: "",
    type: "text",
    unit: "",
    multiLanguage: false,
    min: 0,
};

export function Input({ type, name, label, placeholder, unit, multiLanguage, min }: InputProps) {
    const store = useStore();
    const { values, setValue } = useContext(FormContext);

    const [lang, setLang] = useState(store.lang);

    const value = multiLanguage ? values[name][lang] : values[name];

    const [empty, setEmpty] = useState(value === "");
    const [focus, setFocus] = useState(false);

    return <div className={`input flex${empty ? " empty" : ""}${focus ? " focus" : ""}`}>
        <div>
            {label && <label>{label}</label>}
            {multiLanguage && <span className="lang">
                <button className={lang === "cs" ? "active" : ""} onClick={() => setLang("cs")}>CS</button>
                <button className={lang === "en" ? "active" : ""} onClick={() => setLang("en")}>EN</button>
            </span>}
        </div>
        <div className="flex wrap">
            {type !== "date" && type !== "textarea" && type !== "number" && <input
                placeholder={placeholder}
                type={type}
                name={name}
                value={value}
                onChange={e => {
                    const value = e.target.value;

                    if (multiLanguage) {
                        setValue(name, { ...values[name], [lang]: value });
                    } else {
                        setValue(name, value);
                    }
                }}
                onFocus={() => { setEmpty(false); setFocus(true); }}
                onBlur={() => { setEmpty(value === ""); setFocus(true); }}
            />}
            {type === "number" && <input
                placeholder={placeholder || "0"}
                type={type}
                min={min}
                name={name}
                value={value === 0 ? "" : value}
                onChange={e => {
                    const value = Number(e.target.value);
                    setValue(name, value);
                }}
                onFocus={() => { setEmpty(false); setFocus(true); }}
                onBlur={() => { setEmpty(value === ""); setFocus(true); }}
            />}
            {type === "textarea" && <textarea
                placeholder={placeholder}
                value={value}
                name={name}
                onChange={e => {
                    const value = e.target.value;

                    if (multiLanguage) {
                        setValue(name, { ...values[name], [lang]: value });
                    } else {
                        setValue(name, value);
                    }
                }}
                onFocus={() => { setEmpty(false); setFocus(true); }}
                onBlur={() => { setEmpty(value === ""); setFocus(true); }}
            />}
            {type === "date" && <DatePicker
                locale="cs"
                selected={(() => {
                    const date = new Date(value);
                    if (isNaN(date.getTime())) {
                        return new Date();
                    } else {
                        return date;
                    }
                })()}
                onChange={date => {
                    if (date instanceof Date) {
                        setValue(name, date.toISOString());
                    }
                }}
                customInput={<div className="custom-input">{moment(value).format("LL")}</div>}
            />}

            {unit && <div className="unit">{unit}</div>}
        </div>
    </div>;
}

type CheckboxProps = {
    label: ReactNode,
    name: string,
}

export function Checkbox({ name, label }: CheckboxProps) {
    const { values, setValue } = useContext(FormContext);

    const value = values[name];

    return <div className={`checkbox`}>
        <input name={name} type="checkbox" checked={value} onChange={e => setValue(name, e.target.checked)} />
        <label>{label}</label>
    </div>;
}

export function Switch({ name, labelLeft, labelRight }: { name: string, labelLeft: string, labelRight: string }) {
    const { values, setValue } = useContext(FormContext);

    const value = values[name];

    return <div className={`switch`}>
        <label>{labelLeft}</label>
        <div className={`slider round ${value ? "checked" : ""}`} onClick={e => setValue(name, !value)} ></div>
        <label>{labelRight}</label>
    </div>;
}

export function Submit({ children }: { children: string }) {
    const { onSubmit, isValid, submitMessage } = useContext(FormContext);

    return <>
        {submitMessage && <div className="info">{submitMessage}</div>}
        <button className={`submit ${isValid ? "" : "disable"}`} onClick={() => isValid && onSubmit()}>{children}</button>
    </>;
}
