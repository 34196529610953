import moment from "moment";
import { ReactNode, useEffect } from "react";
import { File as DBFile } from "easy-db-client";
import { resizeImage } from "easy-image-resizer";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import cs from "date-fns/locale/cs";
import "react-datepicker/dist/react-datepicker.css";

import { useStore } from "../store";

registerLocale("cs", cs);
setDefaultLocale("cs");

function dbFile(base64: string): DBFile {
    return {
        type: "EASY_DB_FILE",
        url: base64,
    };
}

export function useTitle(title: string) {
    const { l } = useStore();

    useEffect(() => {
        if (title) {
            document.title = `${title} | ${l("title")}`;
        } else {
            document.title = l("title");
        }
    }, [title, l]);
}

export function Loading() {
    return <div className="loading">
        <div className="loader" />
    </div>;
}

RemoveButton.defaultProps = {
    inline: false,
}

export function RemoveButton({ children, inline, onRemove }: { children: ReactNode, inline: boolean, onRemove: () => void }) {
    return <button
        className={`remove${inline ? " inline" : ""}`}
        onClick={() => {
            const confirmation = window.confirm("Opravdu chcete tuto položku nadobro odstranit?");
            if (confirmation) {
                onRemove();
            }
        }}
    >
        {children}
    </button>
}

export function FileButton({ children, maxWidth, maxHeight, onFile }: { children: ReactNode, maxWidth: number, maxHeight: number, onFile: (file: DBFile) => void }) {
    return <label className="input-file button">
        <input
            type="file"
            onChange={async e => {
                const file = e.target.files?.[0];

                if (file) {
                    const base64 = await resizeImage(file, { maxWidth, maxHeight, type: "jpeg", quality: 0.8 });

                    if (base64)
                        onFile(dbFile(base64));
                }
            }}
        />
        {children}
    </label>;
}


export function DateRangePicker({ dateFrom, dateTo, onChange }: { dateFrom: string, dateTo: string, onChange: (dateFrom: string, dateTo: string) => void }) {
    const { user } = useStore();

    if (user?.admin) {
        const startDate = new Date(dateFrom);
        const endDate = new Date(dateTo);

        return <div className="flex date-picker">
            <DatePicker
                locale="cs"
                selected={startDate}
                onChange={date => {
                    if (date instanceof Date) {
                        onChange(date.toISOString(), dateTo);
                    }
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={<span className="input">{moment(dateFrom).format("LL")}</span>}
            />
            <span className="space">-</span>
            <DatePicker
                locale="cs"
                selected={endDate}
                onChange={date => {
                    if (date instanceof Date) {
                        onChange(dateFrom, date.toISOString());
                    }
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={<span className="input">{moment(dateTo).format("LL")}</span>}
            />
        </div>;
    } else {
        return <div className="date">
            {moment(dateFrom).format("LL")} - {moment(dateTo).format("LL")}
        </div>
    }
}