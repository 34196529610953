import { useTitle } from "../components";

export default function Principles() {

    useTitle("Zásady");

    return <div>
        <h1>Zásady</h1>

        <p>V rámci tohoto dokumentu si Vás dovolujeme seznámit s veškerými důležitými informacemi, které se týkají zpracování Vašich osobních údajů.</p>
        <p>Provozovatelem internetových stránek paltby.cgsa.cz a správcem a zpracovatelem osobních údajů je společnost Czech Gravity Sports Association z.s. IC: 26566311, se sídlem Ve Smečkách 1326/11Praha 1, 110 00(dále jen „CGSA“).</p>

        <h3>Níže najdete odpovědi zejména na následující otázky: </h3>
        <ul>
            <li>Na koho se můžete obrátit v záležitostech souvisejících se zpracováním osobních údajů?</li>
            <li>Za jakým účelem CGSA osobní údaje zpracovává? </li>
            <li>Jaké osobní údaje CGSA zpracovává a jaké k tomu má oprávnění? </li>
            <li>Odkud CGSA získává osobní údaje? </li>
            <li>Komu dále CGSA osobní údaje předává? </li>
            <li>Jak se dozvíte, že CGSA zpracovává Vaše osobní údaje a jaká práva ve věci ochrany osobních údajů můžete uplatnit? </li>
        </ul>

        <p>Společnost CGSA přistupuje odpovědně ke zpracování osobních údajů a zejména dbá na jejich zabezpečení, to vše v souladu s obecně závaznými právními předpisy.</p>

        <h2>1. Na koho se můžete obrátit v záležitostech souvisejících se zpracováním osobních údajů?</h2>

        <p>Na prvním místě se můžete vždy obrátit přímo na společnost CGSA, která je správcem osobních údajů, které zpracovává v souvislosti se svým podnikáním, zejména s provozováním internetových stránek platby.cgsa.cz</p>
        <p>Doručovací adresa: Ve Smečkách 1326/11, Praha 1, 110 00 <br />
E-mail: info@cgsa.cz</p>
        <p>Veškeré kontaktní údaje naleznete na internetových stránkách platby.cgsa.cz</p>

        <h2>2. Ochrana osobních údajů a informace o zpracování</h2>

        <p>2.1   Osobní údaje zpracováváme za tím účelem, abychom Vám mohli poskytnout naše služby.
        Společnost CGSA je poskytovatelem služby platby CGSA. Abychom s Vámi mohli uzavřít smlouvu o poskytování služeb, jejímž předmětem je nákup služeb od společnosti CGSA, potřebujeme zpravidla znát Vaše identifikační a kontaktní údaje.
Vaše osobní údaje zpracováváme i pro potřeby dalšího plnění vyplývajícího z námi poskytnutých služeb.</p>
        <p>2.2  Osobní údaje zpracováváme též za účelem marketingu.
        Pokud se přihlásíte k odběru newsletterů a sdělíte nám Vaši e-mailovou adresu, budeme Vám zasílat obchodní sdělení. V takovém případě se na Vás můžeme obrátit, zejména prostřednictvím e-mailu s nabídkou našich služeb. Svůj souhlas s takovýmto zpracováním osobních údajů můžete kdykoli odvolat.
        Zákazníkům, kterým jsme poskytli naše služby, můžeme zaslat obchodní sdělení (např. ve formě newsletteru), které se týká našich služeb.  K tomu nepotřebujeme Váš souhlas, neboť se jedná o oprávněný zájem společnosti CGSA nabídnout svým zákazníků další zboží a služby. Samozřejmě však nechceme nikoho obtěžovat, nebude-li si to přát.
Takovým způsobem budou Vaše osobní údaje zpracovávány po dobu tří let.</p>
        <p>2.4   Jsme oprávněni zpracovávat Vaše osobní údaje za účelem ochrany našich práv a oprávněných zájmů, typicky v případě jakéhokoli sporu. V tomto případě tedy nepotřebujeme ke zpracování osobních údajů Váš souhlas.</p>
        <p>2.5   Zpracování některých osobních údajů nám ukládá přímo zákon.</p>

        <h2>4. Jaké osobní údaje společnost CGSA zpracovává?</h2>

        <p>Zpracováváme pouze nezbytně nutné základní údaje, a to identifikační údaje (jméno a příjmení) a kontaktní údaje, tj. Váš e-mail a Vaše telefonní číslo.</p>
        <p>Veškeré osobní údaje získáváme přímo od Vás při konkrétním účelu.</p>

        <h2>5. Jaká máme oprávnění k tomu, abychom zpracovávali Vaše osobní údaje?</h2>

        <p>Zpracování osobních údajů provádíme vždy na základě jednoho ze zákonných důvodů pro každý účel zpracování.</p>
        <p>5.1   Ve valné většině případů je zpracování osobních údajů nezbytné pro uzavření a plnění smlouvy. V tomto případě nepotřebujeme ke zpracování Váš souhlas. </p>
        <p>5.2   V některých případech provádíme zpracování osobních údajů z titulu našeho oprávněného zájmu. Jedná se například o zasílání obchodních sdělení nebo v případech, kdy společnost CGSA uplatňuje své nároky nebo chrání svá práva a své oprávněné zájmy. </p>
        <p>5.3   Dalším zákonným důvodem zpracování osobních údajů je zákonná povinnost, která je nám uložena obecně závaznými právními předpisy. </p>
        <p>5.4   V některých případech je zpracování osobních údajů založeno na Vašem souhlasu, a to v případě, že nám sdělíte Vaši e-mailovou adresu pro zasílání obchodních sdělení.</p>

        <h2>6. Komu dále osobní údaje předáváme?</h2>

        <p>Společnost CGSA pečlivě zvažuje a posuzuje, zda je předání osobní údajů jinému příjemci nutné, a zejména zda případný příjemce je schopen zajistit adekvátní ochranu předávaných osobních údajů. K předávání osobních údajů dochází výlučně na základě písemně uzavřené smlouvy o zpracování osobních údajů.</p>
        <p>Společnost CGSA předává osobní údaje:</p>
        <p>6.1   poskytovatelům poštovních a přepravních služeb, potřebujeme-li Vám cokoli doručit prostřednictvím přepravce;</p>
        <p>6.2   některé osobní údaje mohou být na vyžádání předávány příslušným orgánům veřejné moci.</p>
        <p>Osoby, kterým jsou údaje předávány, nejsou oprávněny tyto údaje zpracovávat za jakýmkoli jiným účelem a ani je předávat jakékoli další osobě bez souhlasu nebo pokynu společnosti CGSA, ledaže k tomu mají zákonný důvod.</p>

        <h2>7. Zajímá Vás, jaké osobní údaje zpracováváme o Vaší osobě?</h2>

        <p>V takovém případě se na nás můžete kdykoli obrátit s žádostí o sdělení podrobností o zpracování osobních údajů a my Vám bezplatně poskytneme podrobné informace, případně Vám poskytneme kopii zpracovávaných údajů.</p>
        <p>Jedná se o Vaše právo na přístup k osobním údajům.</p>
        <p>Musíme Vás však upozornit, že v případě opakovaných anebo nedůvodných žádostí jsme oprávněni požadovat za poskytnutí informací úhradu nákladů s tím spojených nebo poskytnutí informace odepřít.</p>

        <h2>8. V jakých dalších záležitostech se na nás můžete obrátit?</h2>

        <p>8.1   Máte právo na opravu osobních údajů, tj. můžete se obrátit na společnost CGSA s žádostí o opravu, jestliže údaje, které o Vás společnost CGSA vede, jsou nepřesné, neúplné či neaktuální.</p>
        <p>9.2   Máte právo na výmaz osobních údajů, tj. můžete se obrátit na společnost CGSA s žádostí, aby Vaše osobní údaje zpracovávané společností CGSA byly vymazány. Aby mohly údaje být vymazány, musí se jednat o jeden z těchto případů: (i) údaje nejsou potřebné pro účel, pro který byly zpracovávány, (ii) pokud jste odvolal/a souhlas se zpracováním údajů, (iii) údaje byly zpracovány protiprávně, (iv) údaje musí být vymazány ke splnění právní povinnosti, nebo (v) údaje byly shromážděny v souvislosti s nabídkou služeb informační společnosti.</p>
        <p>9.3   Máte právo na omezení zpracování osobních údajů, tj. můžete se obrátit na společnost CGSA, aby omezila zpracování Vašich osobních údajů. Aby mohlo zpracování údajů být omezeno, musí se jednat o jeden z těchto případů: (i) popíráte přesnost vašich osobních údajů, nebo (ii) zpracování údajů je protiprávní, ale odmítáte výmaz takových osobních údaj, nebo (iii) budete požadovat zpracování osobních údajů pro potřeby uplatnění Vašich nároků, přestože již byl splněn účel, pro který společnost CGSA údaje zpracovávala, nebo (iv) jste vznesl/a námitku proti zpracování a zatím nebylo ověřeno, zda oprávněný zájem společnosti CGSA převažuje nad Vašimi oprávněnými zájmy.</p>
        <p>9.4   Máte právo na přenos osobních údajů, tj. můžete se obrátit na společnost CGSA, aby Vám Vaše osobní údaje byly poskytnuty ve strukturovaném, běžně používaném a strojově čitelném formátu nebo aby tyto údaje byly rovnou přeneseny k jinému správci. K přenosu údaje může dojít jedině v případě, že zpracování je založeno na uzavřené smlouvě nebo na Vašem souhlasu a zároveň se jedná o automatizované zpracování.</p>
        <p>9.5   Souhlas, který jste udělil/a se zpracováním osobních údajů můžete kdykoli odvolat.</p>

        <h2>9. Kdy je možné vznést námitku proti zpracování osobních údajů?</h2>

        <p>Právo vznést námitku máte v těchto případech.</p>
        <p>Můžete vznést námitku proti zpracování osobních údajů, které společnost CGSA provádí na základě svého oprávněného zájmu. V takovém případě Vaše osobní údaje nebudou dále zpracovávány, ledaže Vám společnost CGSA prokáže, že její oprávněný zájem má přednost před Vašimi zájmy.</p>

        <h2>10. Informace pro jiné fyzické osoby, jejichž osobní údaje zpracovává společnost CGSA</h2>

        <p>Společnost CGSA zpracovává dále osobní údaje jiných fyzických osob, typicky se jedná o osoby spolupracující se společností CGSA, obchodní partnery, odběratele, dodavatele apod. </p>
        <p>I u těchto osob dochází ke zpracování jejich osobních údajů, a to nejčastěji pro účely uzavření a plnění smlouvy.</p>
        <p>O zpracování těchto osobních údajů platí přiměřeně výše uvedené.</p>
        <p>Tyto Zásady nabývají účinnosti dnem 20.1. 2021.</p>

    </div>;
}
