import { useCallback, useEffect, useRef, useState } from "react";
import { useStore } from "../store";

export default function EditText({ children, onSave }: { children: string, onSave: (text: string) => void }) {
    const { user } = useStore();

    const ref = useRef<any>();

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        const span: null | HTMLSpanElement = ref.current;
        if (span) {
            span.innerText = children;
            if (edit) {
                span.contentEditable = "true";
            } else {
                span.contentEditable = "false";
            }
        }
    }, [ref, edit, children]);

    const handleSave = useCallback(() => {
        const span: null | HTMLSpanElement = ref.current;
        if (span) {
            const text = span.innerText;
            onSave(text);
        }
        setEdit(false);
    }, [ref, onSave]);

    if (user?.admin) {
        return <>
            <span ref={ref} className={`editable ${edit ? "edit" : ""}`}>{children}</span>
            <span className="editable-controls">
                {!edit && <button className="inline" onClick={() => setEdit(true)}>✎</button>}
                {edit && <button className="inline" onClick={() => setEdit(false)}>🗙 Zrušit</button>}
                {edit && <button className="inline" onClick={handleSave}>💾 Uložit</button>}
            </span>
        </>;
    } else {
        return <span>{children}</span>;
    }
}
