import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import moment from "moment";
import "moment/locale/cs";

import Layout from './components/Layout';
import ErrorBoundary from './components/ErrorBoundary';
import Routes from './Routes';
import { Provider } from "./store";
import { PayPallProvider } from "./services/payPal";

moment.locale("en");

export default function App() {
    return <ErrorBoundary>
        <Auth0Provider
            domain="cgsa.eu.auth0.com"
            clientId="jt7SuTAsBZ7BkA2OFCjUlyaO4zD9EdQN"
            redirectUri={window.location.origin}
            audience="https://cgsa.eu.auth0.com/api/v2/"
            cacheLocation="localstorage"
        >
            <PayPallProvider>
                <Provider>
                    <BrowserRouter>
                        <Layout>
                            <Routes />
                        </Layout>
                    </BrowserRouter>
                </Provider>
            </PayPallProvider>
        </Auth0Provider>
    </ErrorBoundary>;
}
