import moment from "moment";
import { useHistory } from "react-router-dom";
import { Loading, useTitle } from "../components";
import { useGet, applyAPI } from "../services/api";
import { useStore } from "../store";

import { RaceDB, RacePreview } from "../types";

const NEW_RACE: Omit<RaceDB, "id"> = {
    name: { cs: "", en: "" },
    image: { type: "EASY_DB_FILE", url: "/logo512.png" },
    dateFrom: new Date().toISOString(),
    dateTo: new Date().toISOString(),
    dateRegistrationTo: new Date().toISOString(),
    dateActiveTo: new Date().toISOString(),
    team: true,
    maxMembers: 0,
    pricePerMember: 0,
    commentToApplication: { cs: "Poznámka", en: "Note" },
    items: [],
    applications: [],
};

export default function Races() {
    const { user } = useStore();
    useTitle("");

    const [races, reloadRaces] = useGet("0 GET /api/race/", {}, user);

    const today = moment().startOf("day").valueOf();
    const activeRaces = races === null ? [] : races
        .filter(r => new Date(r.dateActiveTo).getTime() >= today)
        .sort((a, b) => a.dateFrom.localeCompare(b.dateFrom));
    const notActiveRaces = races === null ? [] : races
        .filter(r => new Date(r.dateActiveTo).getTime() < today)
        .sort((a, b) => b.dateFrom.localeCompare(a.dateFrom));

    return <>
        {user?.admin && <button
            onClick={async () => {
                await applyAPI("1 POST /api/race/", NEW_RACE, user);
                reloadRaces();
                window.location.href = "#not-active-races";
            }}
        >
            + Přidat závod
        </button>}
        {races === null && <Loading />}
        {races !== null && activeRaces.length === 0 && <p className="info">Nejsou zde žádné aktivní závody</p>}
        {races !== null && <div className="races flex">
            {activeRaces.map(race => <RaceLink key={race.id} {...race} />)}
        </div>}
        {races !== null && user?.admin && <>
            <h2 id="not-active-races">Neaktivní závody</h2>
            {notActiveRaces.length === 0 && <p className="info">Nejsou zde žádné neaktivní závody</p>}
            <div className="races flex">
                {notActiveRaces.map(race => <RaceLink key={race.id} {...race} />)}
            </div>
        </>}
    </>;
}

function RaceLink({ id, image, name, dateFrom, dateTo }: RacePreview) {
    const { lang, field } = useStore();
    const history = useHistory();

    return <div
        className="flex race-link"
        style={{ backgroundImage: `url(${image.url})` }}
        onClick={() => history.push(`/${lang}/race/${id}`)}
    >
        <h2 className="title">{field(name)}</h2>
        <div className="date">{moment(dateFrom).format("LL")} - {moment(dateTo).format("LL")}</div>
    </div>;
}
